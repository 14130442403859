import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { z } from "zod";
import { clientAccountViewAtom } from "../shared/persistedUserPreferences";
import { useUserDetails } from "./auth/useUserDetails";
import { ONE_HOUR_MS, queryKey } from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

type ClientAppAccountResponse = {
  egoscue: {
    accountIds: number[];
    therapists: {
      id: number;
    }[];
  };
  affiliateOptions: {
    name: string;
    accountId: number;
    therapists: {
      id: number;
    }[];
  }[];
};

export const useClientAppAccounts = (userId?: string) => {
  const { request, isValidLoggedIn } = useHttpClientV2();

  const userDetails = useUserDetails();
  const resolvedId = userId ?? userDetails.data?.userId ?? "-1";

  const [clientAccountView, setClientAccountView] = useAtom(
    clientAccountViewAtom,
  );

  return useQuery(
    [queryKey.clientTherapists, resolvedId],
    async () => {
      const clientTherapists = (
        await request({
          method: "GET",
          uri: `api/v1/GenericUser/GetClientTherapists?clientId=${resolvedId}`,
          responseSchema: z.object({
            Data: z.array(
              z.object({
                TherapistEmail: z.string(),
                AccountId: z.number(),
                AccountType: z.number(),
                AccountName: z.string(),
                ClientUserId: z.number(),
                TherapistUserId: z.number(),
              }),
            ),
          }),
        })
      ).Data;
      const response: ClientAppAccountResponse = {
        egoscue: {
          accountIds: [],
          therapists: [],
        },
        affiliateOptions: [],
      };

      for (const therapist of clientTherapists) {
        // official egoscue
        if (therapist.AccountType === 2) {
          response.egoscue.accountIds.push(therapist.AccountId);
          response.egoscue.therapists.push({ id: therapist.TherapistUserId });

          // affiliate options
        } else if (therapist.AccountType === 4) {
          const matchingAffiliateOption = response.affiliateOptions.find(
            (option) => option.accountId === therapist.AccountId,
          );
          if (matchingAffiliateOption) {
            matchingAffiliateOption.therapists.push({
              id: therapist.TherapistUserId,
            });
          } else {
            response.affiliateOptions.push({
              name: therapist.AccountName,
              accountId: therapist.AccountId,
              therapists: [{ id: therapist.TherapistUserId }],
            });
          }
        }
      }

      if (
        resolvedId === userDetails.data?.userId &&
        response.egoscue.accountIds.length === 0 &&
        clientTherapists.length > 0 &&
        (clientAccountView === -1 ||
          !response.affiliateOptions.some(
            (option) => option.accountId === clientAccountView,
          ))
      ) {
        setClientAccountView(
          response.affiliateOptions.find((account) =>
            account.therapists.some(
              (therapist) =>
                therapist.id === clientTherapists[0].TherapistUserId,
            ),
          )?.accountId ?? -1,
        );
      }

      return response;
    },
    {
      enabled: isValidLoggedIn,
      refetchOnWindowFocus: false,
      staleTime: ONE_HOUR_MS,
    },
  );
};
