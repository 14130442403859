import Close from "@mui/icons-material/Close";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { button } from "../../styles";
import { useDialogPolyfill } from "../../utils/Polyfills";
import style from "./Dialog.module.css";

export const Dialog: FunctionComponent<
  PropsWithChildren<{
    closeDialog?: () => void;
    maxWidth?: string;
    fillHeight?: boolean;
    noPadding?: boolean;
  }>
> = ({
  children,
  closeDialog,
  maxWidth = "520px",
  fillHeight = false,
  noPadding = false,
}) => {
  //We cannot necessarily use the type HTMLDialogElement as it may be polyfilled, so any will be used
  // eslint-disable-next-line
  const modal = useRef<any>(null);
  // eslint-disable-next-line
  const dialogPolyfill = useDialogPolyfill();

  const [usePolyBackdrop, setUsePolyBackdrop] = useState(false);

  useEffect(() => {
    if (dialogPolyfill && modal.current) {
      setUsePolyBackdrop(true);
      dialogPolyfill.registerDialog(modal.current);
    }
    try {
      if (!modal.current?.hasAttribute("open")) {
        modal.current?.showModal();
      }
      const modalElement = modal.current;
      if (closeDialog) {
        modalElement?.addEventListener("close", closeDialog);
      }
      return () => {
        if (closeDialog) {
          modalElement?.removeEventListener("close", closeDialog);
        }
      };
    } catch (e) {
      console.error(e);
    }
  }, [closeDialog]);

  return (
    <>
      <dialog
        ref={modal}
        style={{
          maxWidth: maxWidth,
          height: fillHeight ? "100%" : undefined,
          width: noPadding ? "100%" : undefined,
          padding: noPadding ? 0 : undefined,
        }}
        className={style.dialog}
      >
        {closeDialog && (
          <header className={style.dialogHeader}>
            <button
              onClick={(e) => {
                e.preventDefault();
                //unrender backdrop div if needed
                if (dialogPolyfill) {
                  setUsePolyBackdrop(false);
                }
                closeDialog();
              }}
              className={button.actionButton}
              title="Close dialog"
            >
              <Close />
            </button>
          </header>
        )}
        {children}
      </dialog>
      {usePolyBackdrop && <div className={style.backdrop} />}
    </>
  );
};
