import { useQuery } from "@tanstack/react-query";
import z from "zod";
import { dateCompare } from "../utils/dateCompare";
import { useUserDetails } from "./auth/useUserDetails";
import { ONE_HOUR_MS, queryKey } from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

export const accountSchema = z.object({
  Created: z.string(),
  LastModified: z.string(),
  CreatedBy: z.string().nullable(),
  LastModifiedBy: z.string().nullable(),
  Id: z.number(),
  Name: z.string(),
  Address: z.string(),
  // 0 = Undefined, 1 = Corporate, 2 = Franchisee (Clinic), 4 = Affiliate
  Type: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(4)]),
  // 0 = Undefined, 1 = Active, 2 = Inactive, 4 = Deleted
  Status: z.number(),
  Country: z.string(),
  State: z.string(),
  City: z.string(),
  Zip: z.string(),
  SubscriptionLevel: z.number(),
  TermExpiration: z.string(),
  BillingAmount: z.number(),
  MenuLimit: z.number(),
  SubscriptionPeriod: z.number(),
  MboSiteId: z.number().nullable(),
  AppointmentModificationWindowHours: z.number().nullable(),
});
export type Account = z.infer<typeof accountSchema>;

export const mapAccountType = (type: number): string => {
  return type === 1
    ? "Corporate"
    : type === 2
      ? "Franchisee (Clinic)"
      : type === 4
        ? "Affiliate"
        : "Unknown";
};
export const mapStatus = (type: number): string => {
  return type === 1
    ? "Active"
    : type === 2
      ? "Inactive"
      : type === 4
        ? "Deleted"
        : "Unknown";
};

export const useAccount = ({
  accountId,
  type,
}: {
  accountId: string;
  type: "EPeteAccountId" | "MboSiteId";
}) => {
  const { request, isValidLoggedIn } = useHttpClientV2();

  return useQuery(
    [queryKey.account, type, accountId],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1/GenericAccount/Get?accountId=${accountId}&type=${type}`,
        responseSchema: z.object({
          Data: accountSchema,
        }),
      });
      return result.Data;
    },
    {
      enabled: isValidLoggedIn && accountId !== "-1",
      refetchOnWindowFocus: false,
      staleTime: ONE_HOUR_MS,
    },
  );
};

export const useAccounts = () => {
  const { request, isValidLoggedIn } = useHttpClientV2();
  const userDetails = useUserDetails();

  const accounts = useQuery(
    [queryKey.accounts],
    async () => {
      const result = await request({
        method: "GET",
        uri: "api/v1/GenericAccount/GetAll",
        responseSchema: z.object({
          Data: z.array(accountSchema),
        }),
      });

      return result.Data.sort(dateCompare("Created"));
    },
    {
      enabled:
        isValidLoggedIn &&
        userDetails.status === "success" &&
        userDetails.data.userType === "SuperUser",
    },
  );

  return accounts;
};
