import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { isEntityId } from "../utils/isEntityId";
import {
  FIVE_MINUTE_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
  queryKey,
} from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

const eulaSchema = z.object({
  Uri: z.string(),
  TTL: z.number(),
});
export type Eula = z.infer<typeof eulaSchema>;

export const useEulas = (
  eulas: string[],
  initialValue?: string[],
  ttl?: number,
) => {
  const { request } = useHttpClientV2();

  return useQuery(
    [queryKey.userEulas, eulas.join(",")],
    async () => {
      const docsToSign: string[] = [];

      await Promise.allSettled(
        eulas.map(async (eula) => {
          const eulaRes = await request({
            method: "GET",
            uri: `api/v1.1/UserAdmin/GetUserEulaDocument?doc=${eula}`,
            responseSchema: z.object({
              Data: eulaSchema,
            }),
          });
          docsToSign.push(eulaRes.Data.Uri);
        }),
      );
      return docsToSign;
    },
    {
      ...(initialValue && eulas.length === initialValue.length
        ? { initialData: initialValue }
        : {}),
      notifyOnChangeProps: ["data", "error"],
      refetchInterval: ttl
        ? (ttl - Math.round(Date.now() / 1000)) * 1000
        : FIVE_MINUTE_MS,
      refetchOnWindowFocus: true,
      staleTime: ttl
        ? (ttl - Math.round(Date.now() / 1000)) * 1000
        : 10 * ONE_MINUTE_MS,
      enabled: eulas.length > 0,
      retry: 3,
    },
  );
};

export const useSignEulaMutation = (eulasToSign: string[]) => {
  const queryClient = useQueryClient();
  const { request } = useHttpClientV2();
  const signEulaResponseMutation = useMutation(async () => {
    queryClient.invalidateQueries({
      queryKey: [queryKey.userEulas],
    });
    await request({
      method: "POST",
      uri: "api/v1.1/UserAdmin/SignUserEulaDocuments",
      body: {
        SignedEulas: eulasToSign,
      },
      responseSchema: z.object({
        Data: z.literal("OK"),
      }),
    });
    queryClient.invalidateQueries({
      queryKey: [queryKey.userInfoToken],
    });

    queryClient.invalidateQueries({
      queryKey: [queryKey.userSignedAgreements],
    });
  });
  return signEulaResponseMutation;
};

export const userAgreementSchema = z.object({
  Id: z.number(),
  UserId: z.number(),
  Filename: z.string(),
  Created: z.string(),
  LastModified: z.string(),
  CreatedBy: z.string(),
  LastModifiedBy: z.string(),
});

export const useUserSignedAgreements = (id: number) => {
  const { request, isValidLoggedIn } = useHttpClientV2();

  return useQuery(
    [queryKey.userSignedAgreements, id],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1.1/UserAdmin/GetSignedUserEulaDocuments?userId=${id}`,
        responseSchema: z.object({
          Data: z.array(userAgreementSchema),
        }),
      });
      return result.Data;
    },
    {
      enabled: isValidLoggedIn && isEntityId(id),
      refetchOnWindowFocus: false,
      staleTime: ONE_HOUR_MS,
    },
  );
};
