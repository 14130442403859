import Home from "@mui/icons-material/Home";
import Logout from "@mui/icons-material/Logout";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";
import { logoutCallback } from "../../../api-hooks/auth/logoutCallback";
import { InlineNotification } from "../../../shared/InlineNotification/InlineNotification";
import { button } from "../../../styles";
import style from "./PageLoading.module.css";

export const PageError: FunctionComponent<{
  errorMessage?: string;
}> = ({ errorMessage }) => {
  const router = useRouter();

  return (
    <div className={style.main}>
      <div className={style.innerMain}>
        <InlineNotification
          type="error"
          message={
            errorMessage ?? "There seems to be an issue loading the page."
          }
        />
        <div className={button.buttonRow}>
          <button
            onClick={(e) => {
              e.preventDefault();
              router.push("/", undefined, {
                shallow: true,
              });
            }}
            className={button.buttonSecondary}
          >
            <Home /> Home
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              logoutCallback();
            }}
            className={button.buttonSecondary}
          >
            <Logout /> Log out
          </button>
        </div>
      </div>
    </div>
  );
};
