import { Trans } from "@lingui/macro";
import { useAtom } from "jotai";
import { FunctionComponent, memo } from "react";
import { isImpersonatingAffiliateAtom } from "../api-hooks/auth/useUserDetails";
import { Authorized } from "../shared/Auth/Authorized";
import { DevOnly, getIsDev } from "../shared/FeatureFlag/DevOnly";
import { Checkbox } from "../shared/Form/Checkbox";
import { link } from "../styles";
import style from "./Footer.module.css";
import { IfViewportWidth } from "./IfViewportWidth";
import { LocaleSelector } from "./LocaleSelector";

const _Footer: FunctionComponent<{ centered?: boolean }> = ({ centered }) => {
  const [isImpersonatingAffiliate, setIsImpersonatingAffiliate] = useAtom(
    isImpersonatingAffiliateAtom,
  );
  const isDev = getIsDev();

  return (
    <footer
      className={style.footer}
      style={centered ? { justifyContent: "center" } : {}}
    >
      {/* Adding an empty span so the layouts align in prod  */}
      {isDev ? <LocaleSelector /> : <span></span>}
      <div className={style.innerFooter}>
        <span>&copy; {new Date().getFullYear()} Egoscue Inc.</span>
        <span className={style.footerLinks}>
          <IfViewportWidth type="max">
            <a
              className={link.link}
              href="https://egoscue.formstack.com/forms/epete_user_feedback"
              target="_blank"
              rel="noreferrer"
            >
              <Trans>Feedback</Trans>
            </a>
          </IfViewportWidth>
          <a
            target="_blank"
            href="https://www.egoscue.com/privacy"
            className={link.link}
          >
            <Trans>Privacy</Trans>
          </a>
        </span>
        <DevOnly silent>
          <Authorized
            hideMessage={true}
            allowedUserTypes={["Therapist", "Affiliate"]}
          >
            <Checkbox
              isChecked={isImpersonatingAffiliate}
              toggle={() => {
                setIsImpersonatingAffiliate((i) => !i);
              }}
              label={"Impersonate affiliate"}
            />
          </Authorized>
        </DevOnly>
      </div>
    </footer>
  );
};

export const Footer = memo(_Footer);
