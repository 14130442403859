import { UseQueryResult } from "@tanstack/react-query";
import { useUserDetails } from "../auth/useUserDetails";
import { useClientAppAccounts } from "../useClientAppAccounts";
import { useHttpClientV2 } from "../useHttpClientV2";
import { User, useUser } from "../useUsers";
import { CustomUseQueryResult } from "../util/CustomUseQueryResult";
import { useCrmClientSync } from "./useCrmClientSync";

export const hasConfirmedFields = (user: User) => {
  return user.ConfirmedFieldsDate !== null;
};

export const useIsInitialCrmQueryPossible = (
  userId?: string,
): CustomUseQueryResult<boolean> => {
  const { isValidLoggedIn } = useHttpClientV2();

  const userDetails = useUserDetails();
  const resolvedId = userId ?? userDetails.data?.userId ?? "-1";

  const clientAppAccounts = useClientAppAccounts(resolvedId);
  const user = useUser(parseInt(resolvedId));

  if (
    userDetails.status === "loading" ||
    clientAppAccounts.status === "loading" ||
    user.status === "loading"
  ) {
    return { status: "loading" };
  }

  if (
    userDetails.status === "error" ||
    clientAppAccounts.status === "error" ||
    user.status === "error"
  ) {
    return { status: "error" };
  }

  return {
    status: "success",
    data:
      isValidLoggedIn &&
      userDetails.status === "success" &&
      user.status === "success" &&
      (userDetails.data.userType === "Client"
        ? hasConfirmedFields(user.data)
        : true) &&
      (userDetails.data.accountType !== "Affiliate" ||
        (clientAppAccounts.status === "success" &&
          clientAppAccounts.data.egoscue.accountIds.length > 0)),
  };
};

export const useIsCrmQueryPossible = (
  userId?: string,
): {
  data: boolean;
  status: UseQueryResult["status"];
} => {
  const isInitialCrmQueryPossible = useIsInitialCrmQueryPossible(userId);

  const clientSyncIds = useCrmClientSync(userId);

  if (
    isInitialCrmQueryPossible.status === "loading" ||
    clientSyncIds.status === "loading"
  ) {
    return { status: "loading", data: false };
  }

  if (
    isInitialCrmQueryPossible.status === "error" ||
    clientSyncIds.status === "error"
  ) {
    return { status: "error", data: false };
  }

  return {
    status: "success",
    data:
      isInitialCrmQueryPossible.status === "success" &&
      isInitialCrmQueryPossible.data &&
      clientSyncIds.status === "success" &&
      clientSyncIds.data.length > 0,
  };
};
