import { I18nProvider } from "@lingui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppType } from "next/app";
import Head from "next/head";
import { useRef } from "react";
import { RefreshingTokenNotice } from "../api-hooks/auth/RefreshingTokenNotice";
import { useAuthentication } from "../api-hooks/auth/useAuthentication";
import { useCrmClientSync } from "../api-hooks/crm/useCrmClientSync";
import { Header } from "../layouts/Header/Header";
import { ThemeProvider } from "../layouts/ThemeProvider";
import { usePageTransitionAnimationContainer } from "../layouts/usePageTransitionAnimationContainer";
import ErrorBoundary from "../shared/ErrorBoundary/ErrorBoundary";
import { PrintProvider } from "../shared/PrintProvider/PrintProvider";
import "../styles/globals.css";
import { useI18n } from "../translations/utils";
import { ClientOnly } from "../utils/ClientOnly";
import { PolyfillProvider } from "../utils/Polyfills";

const App: AppType = ({ Component, pageProps }) => {
  const animated = usePageTransitionAnimationContainer();

  useAuthentication();
  useCrmClientSync();

  return (
    <ClientOnly>
      <ThemeProvider>
        <PrintProvider>
          <PolyfillProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
            </Head>
            <ClientOnly>
              <Header />
            </ClientOnly>
            <div className="mainAppContainer" ref={animated}>
              <Component {...pageProps} />
              <RefreshingTokenNotice />
            </div>
          </PolyfillProvider>
        </PrintProvider>
      </ThemeProvider>
    </ClientOnly>
  );
};

const WithQueryClientProvider: AppType = (props) => {
  const queryClient = useRef(new QueryClient());

  return (
    <QueryClientProvider client={queryClient.current}>
      <App {...props} />
    </QueryClientProvider>
  );
};

const WithI18N: AppType = (props) => {
  const i18n = useI18n();

  return (
    <I18nProvider i18n={i18n}>
      <WithQueryClientProvider {...props} />
    </I18nProvider>
  );
};

const WithErrorBoundary: AppType = (props) => {
  return (
    <ErrorBoundary>
      <WithI18N {...props} />
    </ErrorBoundary>
  );
};

//Do not remove this, automatic static optimization will interfere with program's ability to get ENV
WithErrorBoundary.getInitialProps = async () => {
  return { automaticStaticOptimizationDisabler: true };
};

export default WithErrorBoundary;
