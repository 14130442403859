import { t } from "@lingui/macro";
import { useQuery } from "@tanstack/react-query";
import z from "zod";
import { isEntityId } from "../utils/isEntityId";
import { UserType, useUserDetails } from "./auth/useUserDetails";
import { ONE_HOUR_MS, queryKey } from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

export const userSchema = z.object({
  UserId: z.number(),
  KeycloakId: z.string(),
  AccountId: z.number(),
  Email: z.string(),
  Type: z.number(),
  Status: z.number(),
  FirstName: z.string(),
  LastName: z.string(),
  Gender: z.string(),
  Birthday: z.string(),
  Height: z.number(),
  Phone: z.string(),
  Thumbnail: z.string().nullable(),
  LastVisitDate: z.string().nullable(),
  VisitCount: z.number(),
  ConfirmedFieldsDate: z.string().nullable(),
});

const getUsersResponseSchema = z.object({
  Data: z.object({
    Results: z.array(userSchema),
    Page: z.number(),
    Count: z.number(),
    Next: z.boolean(),
  }),
});

export type User = z.infer<typeof userSchema>;

export const useUser = (id: number) => {
  const { request, isValidLoggedIn } = useHttpClientV2();

  return useQuery(
    [queryKey.user, id],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1.1/UserAdmin/GetUser?userId=${id}`,
        responseSchema: z.object({
          Data: userSchema,
        }),
      });
      return result.Data;
    },
    {
      enabled: isValidLoggedIn && isEntityId(id),
      refetchOnWindowFocus: false,
      staleTime: ONE_HOUR_MS,
    },
  );
};

export const useUsers = ({
  accountId,
  searchTerm = "",
  page = 0,
  external = false,
}: {
  accountId: string;
  searchTerm: string;
  page: number;
  external?: boolean;
}) => {
  const { request, isValidLoggedIn } = useHttpClientV2();

  const trimmedSearch = searchTerm.trim();

  const userDetails = useUserDetails();

  return useQuery(
    [queryKey.users, accountId, trimmedSearch, external, page],
    async () => {
      if (trimmedSearch === "" && !external) {
        const result = await request({
          method: "GET",
          uri: `api/v1.1/UserAdmin/GetUsersByAccount?&count=12&page=${page}`,
          responseSchema: getUsersResponseSchema,
        });

        return result.Data;
      } else {
        const result = await request({
          method: "GET",
          uri: `api/v1.1/UserAdmin/Search?isExternal=${external}&count=12&page=${page}&searchTerm=${encodeURIComponent(
            trimmedSearch,
          )}`,
          responseSchema: getUsersResponseSchema,
        });

        return result.Data;
      }
    },
    {
      enabled:
        isValidLoggedIn &&
        (external
          ? canPerformExternalSearch(
              userDetails.status === "success"
                ? userDetails?.data.userType
                : undefined,
              searchTerm,
            )
          : accountId !== "-1"),
    },
  );
};

export const mapUserType = (type: number): string => {
  return type === 1
    ? t`Client`
    : type === 2
      ? t`Therapist`
      : type === 4
        ? t`Clinic owner`
        : type === 8
          ? t`Super user`
          : t`Unknown`;
};

export const canPerformExternalSearch = (
  userType: UserType | undefined,
  searchTerm: string,
): boolean => {
  if (userType === "SuperUser") {
    return !!searchTerm;
  }
  if (userType === "Therapist") {
    const splitSearch = searchTerm.split(" ");
    if (
      searchTerm.includes("@") ||
      (splitSearch.length > 1 && splitSearch.every((name) => name.length > 1))
    ) {
      return true;
    }
  }
  return false;
};
