import Keycloak from "keycloak-js";
import getConfig from "next/config";

export const logoutCallback = () => {
  const { publicRuntimeConfig } = getConfig();

  const keycloak = new Keycloak({
    url: publicRuntimeConfig.AUTH_URL,
    realm: "epete3",
    clientId: "epete3-app",
  });

  keycloak.init({}).then(() => {
    keycloak.logout({ redirectUri: `${window.location.origin}/logged-out` });
  });
};
