import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

/**
 * @description used to render the content after mount
 * @param ReactNode children to be rendered after useEffect fires
 * @returns ReactNode if useEffect has fired, else JSX fragment
 */
export const ClientOnly: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return <>{hasMounted ? children : null}</>;
};
