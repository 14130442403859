import { useRouter } from "next/router";
import { form } from "../styles";
import languages from "../translations/languages";

export const LocaleSelector = () => {
  const router = useRouter();
  const locale = router.locale || router.defaultLocale!;

  return (
    <select
      style={{ maxWidth: "20ch", marginBottom: "var(--spacerLg)" }}
      className={form.input}
      value={locale}
      onChange={(e) => {
        const newLocale = e.target.value;
        const currentUrl = new URL(window.location.href);
        const pathname = currentUrl.pathname;

        const matchingLanguage = languages.find((language) =>
          pathname.startsWith(`/${language.locale}`),
        );

        const newPathname = matchingLanguage
          ? `/${newLocale}${pathname.slice(matchingLanguage.locale.length + 1)}`
          : `/${newLocale}${pathname}`;

        window.location.href = `${newPathname}?${currentUrl.searchParams.toString()}`;
      }}
    >
      {languages.map((language) => (
        <option key={language.locale} value={language.locale}>
          {language.display}
        </option>
      ))}
    </select>
  );
};
