import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import jwtDecode from "jwt-decode";
import getConfig from "next/config";
import { ONE_DAY_MS, THIRTY_DAYS_MS } from "../queryKey";
import { parseUserInfoToken } from "./useUserDetails";

type NotLoggedInState = { isLoggedIn: false };
type LoggedInState = {
  isLoggedIn: true;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  userInfoToken: string;
  accessExpiryTime: number;
  loginTime: number;
};
type LoginState = NotLoggedInState | LoggedInState;

const { publicRuntimeConfig } = getConfig();
const DEPLOY_ENV = publicRuntimeConfig.DEPLOY_ENV;

const cookieDomain = DEPLOY_ENV === "local" ? {} : { domain: "egoscue.com" };

const loginStateKey = `loginState-v2-${DEPLOY_ENV}`;

export const getLoginState = (key = loginStateKey): LoginState => {
  const isLoggedIn = getCookie(`${key}-IS_LOGGED_IN`, {
    ...cookieDomain,
  })?.valueOf();

  if (isLoggedIn) {
    const accessExpiryTime = getCookie(`${key}-ACCESS_EXPIRY_TIME`, {
      ...cookieDomain,
    })?.valueOf();
    const accessToken = getCookie(`${key}-ACCESS_TOKEN`, {
      ...cookieDomain,
    })?.valueOf();
    const idToken = getCookie(`${key}-ID_TOKEN`, {
      ...cookieDomain,
    })?.valueOf();
    const loginTime = getCookie(`${key}-LOGIN_TIME`, {
      ...cookieDomain,
    })?.valueOf();
    const refreshToken = getCookie(`${key}-REFRESH_TOKEN`, {
      ...cookieDomain,
    })?.valueOf();
    const userInfoToken = getCookie(`${key}-USER_INFO_TOKEN`, {
      ...cookieDomain,
    })?.valueOf();

    return {
      isLoggedIn: true,
      accessExpiryTime: parseFloat(accessExpiryTime as string),
      accessToken,
      idToken,
      loginTime: parseFloat(loginTime as string),
      refreshToken,
      userInfoToken,
    } as LoginState;
  } else {
    return {
      isLoggedIn: false,
    };
  }
};

export const setLoginState = (newValue: LoginState, key = loginStateKey) => {
  let expires = new Date(new Date().getTime() + ONE_DAY_MS);
  if (newValue.isLoggedIn) {
    const userInfo = parseUserInfoToken(newValue.userInfoToken);
    if (userInfo.status === "success" && userInfo.data.userType === "Client") {
      expires = new Date(new Date().getTime() + THIRTY_DAYS_MS);
    }
  }

  setCookie(`${key}-IS_LOGGED_IN`, newValue.isLoggedIn, {
    ...cookieDomain,
  });

  if (newValue.isLoggedIn) {
    setCookie(`${key}-ACCESS_EXPIRY_TIME`, newValue.accessExpiryTime, {
      ...cookieDomain,
      expires: expires,
    });
    setCookie(`${key}-ACCESS_TOKEN`, newValue.accessToken, {
      ...cookieDomain,
      expires: expires,
    });
    setCookie(`${key}-ID_TOKEN`, newValue.idToken, {
      ...cookieDomain,
      expires: expires,
    });
    setCookie(`${key}-LOGIN_TIME`, newValue.loginTime, {
      ...cookieDomain,
      expires: expires,
    });
    setCookie(`${key}-REFRESH_TOKEN`, newValue.refreshToken, {
      ...cookieDomain,
      expires: expires,
    });
    setCookie(`${key}-USER_INFO_TOKEN`, newValue.userInfoToken, {
      ...cookieDomain,
      expires: expires,
    });
  }
};

export const loginAtom = atomWithStorage<LoginState>(
  loginStateKey,
  {
    isLoggedIn: false,
  },
  {
    getItem(key) {
      return getLoginState(key);
    },
    setItem(key, newValue) {
      setLoginState(newValue, key);
    },
    removeItem(key) {
      deleteCookie(key, { ...cookieDomain });
    },
  },
);

export const isRefreshTokenExpiredOrMalformed = (
  loginState: LoggedInState,
): boolean => {
  try {
    const parsedRefreshToken = jwtDecode<{ exp: number }>(
      loginState.refreshToken,
    );
    if (!parsedRefreshToken.exp) {
      throw new Error("Refresh Token has not expiration");
    }
    const refreshTokenExpiryUtcSeconds = parsedRefreshToken.exp;
    const currentTimeUtcSeconds = new Date().getTime() / 1000;

    return currentTimeUtcSeconds > refreshTokenExpiryUtcSeconds;
  } catch (e) {
    console.error("Refresh Token Error: ", e);
    return true;
  }
};

export const signingAtom = atom<
  {
    Uri: string;
    TTL: number;
  }[]
>([]);
