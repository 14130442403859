import { FunctionComponent, PropsWithChildren, useEffect, useRef } from "react";

// eslint-disable-next-line
let dialogPolyfill: any;

export const useDialogPolyfill = () => {
  return dialogPolyfill;
};

export const PolyfillProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  // eslint-disable-next-line
  const dialogPolyfillRef = useRef<any>(undefined);

  useEffect(() => {
    if (
      typeof HTMLDialogElement !== "function" &&
      typeof window !== "undefined" &&
      typeof dialogPolyfillRef?.current?.registerDialog !== "function"
    ) {
      dialogPolyfillRef.current = module.require("dialog-polyfill").default;
    }
    dialogPolyfill = dialogPolyfillRef.current;
  }, []);

  return <>{children}</>;
};
