import { t } from "@lingui/macro";
import { useAtomValue } from "jotai";
import { useMemo, useRef } from "react";
import { useRerender } from "../../components/Client/Experiments/useRerender";
import { Dialog } from "../../shared/Dialog/Dialog";
import { InlineNotification } from "../../shared/InlineNotification/InlineNotification";
import { useInterval } from "../../utils/useInterval";
import { useVisibility } from "../../utils/useVisibility";
import { TWENTY_SECONDS_MS } from "../queryKey";
import { loginAtom } from "./login";

export const RefreshingTokenNotice = () => {
  const loginState = useAtomValue(loginAtom);

  const rerender = useRerender();

  const enableTimeAccrual = useRef(true);

  const activeWaitTimeForRefresh = useRef(0);

  const initialVis = useVisibility(
    () => {
      enableTimeAccrual.current = true;
    },
    () => {
      enableTimeAccrual.current = false;
    },
    "refreshNoticeEnabled",
  );

  useMemo(() => {
    if (initialVis.current === "hidden") {
      enableTimeAccrual.current = false;
    }
  }, [initialVis.current]);

  const timeRefreshStarted = useRef(-1);

  const isSessionExpired =
    loginState.isLoggedIn &&
    loginState.accessExpiryTime - new Date().getTime() / 1000 < 0;

  if (timeRefreshStarted.current === -1 && isSessionExpired) {
    timeRefreshStarted.current = new Date().getTime();
  }

  if (timeRefreshStarted.current !== -1 && !isSessionExpired) {
    timeRefreshStarted.current = -1;
    activeWaitTimeForRefresh.current = 0;
  }

  useInterval(() => {
    if (enableTimeAccrual && timeRefreshStarted.current !== -1) {
      activeWaitTimeForRefresh.current =
        activeWaitTimeForRefresh.current + 1000;
    }
    if (
      timeRefreshStarted.current !== -1 &&
      activeWaitTimeForRefresh.current > TWENTY_SECONDS_MS
    ) {
      // we have been trying to refresh for too long :(
      console.log("refreshing");
      window.location.reload();
    }
    rerender();
  }, 1000);

  return (
    <>
      {isSessionExpired && (
        <Dialog maxWidth="320px">
          <InlineNotification
            message={t`Refreshing your session...`}
            type={"loading"}
          />
        </Dialog>
      )}
    </>
  );
};
