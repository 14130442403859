import { Trans } from "@lingui/macro";
import Campaign from "@mui/icons-material/Campaign";
import { button } from "../styles";
import style from "./FeedbackLink.module.css";
import { IfViewportWidth } from "./IfViewportWidth";

export const FeedbackLink = () => {
  return (
    <IfViewportWidth type="min">
      <a
        className={`${style.feedbackLink} ${button.buttonPrimary}`}
        href="https://egoscue.formstack.com/forms/epete_user_feedback"
        target="_blank"
        rel="noreferrer"
      >
        <Campaign /> <Trans>Feedback</Trans>
      </a>
    </IfViewportWidth>
  );
};
