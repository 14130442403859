import getConfig from "next/config";
import { FunctionComponent, PropsWithChildren } from "react";

export const DevOnly: FunctionComponent<
  PropsWithChildren & { silent: boolean }
> = ({ children, silent }) => {
  const isDev = getIsDev();

  return <>{isDev ? children : silent ? <></> : "Unauthorized"}</>;
};

export const getIsDev = () => {
  const { publicRuntimeConfig } = getConfig();
  const DEPLOY_ENV = publicRuntimeConfig.DEPLOY_ENV;

  return DEPLOY_ENV !== "prod";
};
