export const onlyUnique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index;
};

export const getUniqueValuesByField = <T>(input: T[], field: keyof T): T[] => {
  const uniqueValues: T[] = [];
  const fieldDictionary: Record<string, boolean> = {};

  input.forEach((el) => {
    const fieldValue = el[field] as string;

    if (!fieldDictionary[fieldValue]) {
      uniqueValues.push(el);
      fieldDictionary[fieldValue] = true;
    }
  });

  return uniqueValues;
};

// written by chat gpt woo hoo!
export const getUniqueValuesByFieldWithCount = <T>(
  input: T[],
  field: keyof T,
): (T & { __count: number })[] => {
  const uniqueValues: T[] = [];
  const duplicateCountByFieldValue: Partial<Record<string, number>> = {};

  input.forEach((el) => {
    const fieldValue = el[field] as string;
    if (!duplicateCountByFieldValue[fieldValue]) {
      uniqueValues.push(el);
    }
    const currentCount = duplicateCountByFieldValue[fieldValue];
    duplicateCountByFieldValue[fieldValue] = currentCount
      ? currentCount + 1
      : 1;
  });

  return uniqueValues.map((value) => ({
    ...value,
    __count: duplicateCountByFieldValue[value[field] as string] ?? 1,
  }));
};

export const getDeduplicatedViaCompositeKey = <T>(
  input: T[],
  field1: keyof T,
  field2: keyof T,
  skipPredicate: (input: T) => boolean = () => false,
): T[] => {
  const uniqueValues: T[] = [];
  const duplicateCountByFieldValue: Partial<Record<string, number>> = {};

  input.forEach((el) => {
    const fieldValue = `${el[field1]} - ${el[field2]}`;
    if (!duplicateCountByFieldValue[fieldValue] || skipPredicate(el)) {
      uniqueValues.push(el);
    }
    const currentCount = duplicateCountByFieldValue[fieldValue];
    duplicateCountByFieldValue[fieldValue] = currentCount
      ? currentCount + 1
      : 1;
  });

  return uniqueValues;
};
