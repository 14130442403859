import { Trans, t } from "@lingui/macro";
import Logout from "@mui/icons-material/Logout";
import Refresh from "@mui/icons-material/Refresh";
import { captureException } from "@sentry/nextjs";
import { Router, withRouter } from "next/router";
import { Component, ErrorInfo, PropsWithChildren } from "react";
import { logoutCallback } from "../../api-hooks/auth/logoutCallback";
import { FeedbackLink } from "../../layouts/FeedbackLink";
import { Footer } from "../../layouts/Footer";
import { button } from "../../styles";
import { InlineNotification } from "../InlineNotification/InlineNotification";
import style from "./ErrorBoundary.module.css";

class ErrorBoundary extends Component<
  PropsWithChildren & { router: Router },
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren & { router: Router }) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureException(error);
    console.log({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <main className={style.main}>
            <InlineNotification
              type="error"
              message={t`An unexpected error occurred trying to perform that action...`}
            />
            <div className={button.buttonRow}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ hasError: false });
                }}
                type="button"
                className={button.buttonPrimary}
              >
                <Refresh /> <Trans>Refresh app</Trans>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  logoutCallback();
                }}
                className={button.buttonSecondary}
              >
                <Logout /> <Trans>Log out</Trans>
              </button>
            </div>
          </main>
          <FeedbackLink />
          <Footer />
        </>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
