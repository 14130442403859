import autoAnimate, { AnimationController } from "@formkit/auto-animate";
import { MutableRefObject, useEffect, useRef, useState } from "react";

export const useAnimationContainer = <T extends HTMLElement | null = null>(
  disabled = false,
): MutableRefObject<T | null> => {
  const ref = useRef<T | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    let controller: AnimationController | null = null;
    if (disabled) {
      return;
    }
    if (!isAnimating) {
      if (ref.current) {
        setIsAnimating(true);
        controller = autoAnimate(ref.current, {});
      } else {
        setIsAnimating(false);
      }
    }
    return () => {
      if (isAnimating) {
        controller?.disable();
      }
    };
  }, [isAnimating, ref]);

  return ref;
};

export const useAnimationOnRef = <T extends HTMLElement | null = null>(
  ref: MutableRefObject<T | null>,
): void => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    let controller: AnimationController | null = null;
    if (!isAnimating) {
      if (ref.current) {
        setIsAnimating(true);
        controller = autoAnimate(ref.current, {});
      } else {
        setIsAnimating(false);
      }
    }
    return () => {
      if (isAnimating) {
        controller?.disable();
      }
    };
  }, [isAnimating, ref]);
};
