import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { isEntityId } from "../utils/isEntityId";
import { useUserDetails } from "./auth/useUserDetails";
import { queryKey } from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

export const useAddClientUploadTherapistTaskMutation = () => {
  const { request } = useHttpClientV2();
  const queryClient = useQueryClient();

  return useMutation(async () => {
    await request({
      method: "POST",
      uri: "api/v1/TherapistTasks/AddReviewClientUploadTask",
      responseSchema: z.object({
        Data: z.unknown(),
      }),
    });

    queryClient.invalidateQueries({
      queryKey: [queryKey.therapistTasks],
    });
    queryClient.invalidateQueries({
      queryKey: [queryKey.outstandingTherapistTasksCount],
    });
  });
};

export const useUpdateTaskMutation = () => {
  const { request } = useHttpClientV2();
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { taskId: number; status: TherapistTask["Status"] }) => {
      await request({
        method: "POST",
        uri: "api/v1/TherapistTasks/UpdateTask",
        body: {
          TaskId: args.taskId,
          TaskStatus: args.status,
        },
        responseSchema: z.object({
          Data: z.unknown(),
        }),
      });

      queryClient.invalidateQueries({
        queryKey: [queryKey.therapistTasks],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKey.outstandingTherapistTasksCount],
      });
    },
  );
};

export const useOutstandingTherapistTasksCount = () => {
  const { request } = useHttpClientV2();

  const userDetails = useUserDetails();

  return useQuery(
    [queryKey.outstandingTherapistTasksCount],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1/TherapistTasks/GetOutstandingTasksCount?therapistUserId=${userDetails.data?.userId ?? -1}`,
        responseSchema: z.object({
          Data: z.number(),
        }),
      });

      return result.Data;
    },
    {
      enabled:
        userDetails.status === "success" &&
        isEntityId(parseInt(userDetails.data.userId)),
    },
  );
};

//making secondary hook as both pieces of data need to be used simultaneously
export const useOutstandingTherapistTasksForAccountCount = (
  enabler: boolean,
) => {
  const { request } = useHttpClientV2();

  const userDetails = useUserDetails();

  return useQuery(
    [queryKey.outstandingTherapistTasksForAccountCount],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1/TherapistTasks/GetOutstandingTasksCountForAccount?accountId=${userDetails.data?.accountId ?? -1}`,
        responseSchema: z.object({
          Data: z.number(),
        }),
      });

      return result.Data;
    },
    {
      enabled:
        enabler &&
        userDetails.status === "success" &&
        isEntityId(parseInt(userDetails.data.accountId)),
    },
  );
};

const therapistTaskSchema = z.object({
  Id: z.number(),
  Created: z.string(),
  LastModified: z.string(),
  CreatedBy: z.string(),
  LastModifiedBy: z.string(),
  ToDoDate: z.string(),
  ClientUserId: z.number(),
  TargetId: z.number(),
  TherapistUserId: z.number(),
  CompletedByUserId: z.number().nullable(),
  Status: z.union([
    z.literal("TODO"),
    z.literal("ACKNOWLEDGED"),
    z.literal("DONE"),
  ]),
  TaskType: z.union([
    z.literal("HANDLE_CLIENT_UPLOAD"),
    z.literal("REVIEW_REFERRAL"),
    z.literal("REVIEW_SURVEY_RESPONSE"),
    z.literal("VISIT_FOLLOW_UP"),
    z.literal("CLIENT_URGENT_MESSAGE"),
    z.literal("CLIENT_PAIN_UPDATE"),
  ]),
  FirstName: z.string(),
  LastName: z.string(),
  TherapistFirstName: z.string().nullable(),
  TherapistLastName: z.string().nullable(),
});
export type TherapistTask = z.infer<typeof therapistTaskSchema>;

export const useTherapistTasks = (expandOrFilter?: {
  allTasksForAccount: boolean;
}) => {
  const { request } = useHttpClientV2();

  const userDetails = useUserDetails();

  const filterKey = expandOrFilter ? JSON.stringify(expandOrFilter) : "";

  return useQuery(
    [queryKey.therapistTasks, filterKey],
    async () => {
      const result = await request({
        method: "GET",
        uri: `api/v1/TherapistTasks/GetTasks${expandOrFilter?.allTasksForAccount ? `ForAccount?accountId=${userDetails.data?.accountId ?? -1}` : `?therapistUserId=${userDetails.data?.userId ?? -1}`}`,
        responseSchema: z.object({
          Data: z.array(therapistTaskSchema),
        }),
      });

      return result.Data;
    },
    {
      enabled:
        userDetails.status === "success" &&
        isEntityId(parseInt(userDetails.data.userId)),
    },
  );
};
