import { atom, useAtomValue } from "jotai";
import jwtDecode from "jwt-decode";
import { z } from "zod";
import { CustomUseQueryResult } from "../util/CustomUseQueryResult";
import { loginAtom, signingAtom } from "./login";

export const userTypeSchema = z.union([
  z.literal("Affiliate"),
  z.literal("Client"),
  z.literal("Therapist"),
  z.literal("SuperUser"),
  z.literal("Unknown"),
  z.literal("ClinicOwner"),
]);

export const statusSchema = z.union([
  z.literal("Active"),
  z.literal("Inactive"),
  z.literal("Deleted"),
  z.literal("Unknown"),
]);

export const accountTypeSchema = z.union([
  z.literal("Corporate"),
  z.literal("Franchisee"),
  z.literal("Affiliate"),
  z.literal("Undefined"),
]);

const decodedUserInfoTokenSchema = z.object({
  account_id: z.string(),
  aud: z.string(),
  email: z.string(),
  first_name: z.string(),
  iss: z.string(),
  keycloak_id: z.string(),
  last_name: z.string(),
  phone: z.string(),
  user_id: z.string(),
  user_type: userTypeSchema,
  user_status: statusSchema,
  account_status: statusSchema,
  account_type: accountTypeSchema,
  outstanding_eulas: z.optional(z.string()),
  roles: z.optional(z.string()),
});
export type DecodedUserInfoToken = z.infer<typeof decodedUserInfoTokenSchema>;

const userDetailsSchema = z.object({
  userType: userTypeSchema,
  userStatus: statusSchema,
  accountStatus: statusSchema,
  accountType: accountTypeSchema,
  accountId: z.string(),
  userId: z.string(),
  familyName: z.string(),
  givenName: z.string(),
  phone: z.string(),
  uniqueName: z.string(),
  outstandingEulas: z.optional(z.string()),
  outstandingEulaUrls: z.optional(
    z.array(
      z.object({
        Uri: z.string(),
        TTL: z.number(),
      }),
    ),
  ),
  roles: z.optional(z.string()),
});
export type UserDetails = z.infer<typeof userDetailsSchema>;
export type UserType = z.infer<typeof userTypeSchema>;

export const parseUserInfoToken = (
  token: string,
  isImpersonatingAffiliate?: boolean,
): CustomUseQueryResult<UserDetails> => {
  let userInfo;

  try {
    userInfo = decodedUserInfoTokenSchema.parse(jwtDecode(token));
  } catch (e) {
    console.error("User Info Token Error: ", e);
    return { status: "error" };
  }

  const userDetails: UserDetails = {
    userStatus: userInfo.user_status,
    accountStatus: userInfo.account_status,
    accountType: isImpersonatingAffiliate ? "Affiliate" : userInfo.account_type,
    userType: isImpersonatingAffiliate ? "Affiliate" : userInfo.user_type,
    accountId: userInfo.account_id,
    userId: userInfo.user_id,
    familyName: userInfo.last_name,
    givenName: userInfo.first_name,
    phone: userInfo.phone,
    uniqueName: userInfo.email,
    outstandingEulas: userInfo.outstanding_eulas,
    roles: userInfo.roles,
  };

  return { status: "success", data: userDetails };
};

export const isImpersonatingAffiliateAtom = atom<boolean>(false);

export const useUserDetails = (): CustomUseQueryResult<UserDetails> => {
  const state = useAtomValue(loginAtom);

  const toSign = useAtomValue(signingAtom);

  const isImpersonatingAffiliate = useAtomValue(isImpersonatingAffiliateAtom);

  if (!state.isLoggedIn) {
    return { status: "loading" };
  }

  const user = parseUserInfoToken(
    state.userInfoToken,
    isImpersonatingAffiliate,
  );

  if (user.data && toSign.length > 0) {
    user.data.outstandingEulaUrls = toSign;
  }

  return user;
};
