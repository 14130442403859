export const queryKey = {
  account: "account",
  accounts: "accounts",
  user: "user",
  users: "users",
  exercises: "exercises",
  issues: "issues",
  images: "images",
  clientImages: "clientImages",
  menuByGuid: "menuByGuid",
  menus: "menus",
  menuBasicInfo: "menuBasicInfo",
  menuSentDates: "menuSentDates",
  menuEmailRecords: "menuEmailRecords",
  menuUsage: "menuUsage",
  postures: "postures",
  visit: "visit",
  visits: "visits",
  visitCounts: "visitCounts",
  notes: "notes",
  reassessments: "reassessments",
  devices: "devices",
  postureObjectives: "postureObjectives",
  exerciseRecommendations: "exerciseRecommendations",
  previouslyAssignedExercises: "previouslyAssignedExercises",
  feedbackResponses: "feedbackResponses",
  exerciseInstructions: "exerciseInstructions",
  allAssignedExercisesInstructions: "allAssignedExercisesInstructions",
  feedbackQuestions: "feedbackQuestions",
  userDetails: "userDetails",
  clientAppAccounts: "clientAppAccounts",
  clientTherapists: "clientTherapists",
  adhocMenuCount: "adhocMenuCount",
  informationalVisitId: "informationalVisitId",
  accountTherapists: "accountTherapists",
  userEulas: "userEulas",
  userSignedAgreements: "userSignedAgreements",
  userInfoToken: "userInfoToken",
  keyCloakInitialization: "keycloakInitialization",
  userPreferences: "userPreferences",
  supplementalRolePermissionDetails: "supplementalRolePermissionDetails",
  referrals: "referrals",
  userAuthoredReferrals: "userAuthoredReferrals",
  outstandingTherapistTasksCount: "outstandingTherapistTasksCount",
  outstandingTherapistTasksForAccountCount:
    "outstandingTherapistTasksForAccountCount",
  therapistTasks: "therapistTasks",
};

export const THREE_SECONDS_MS = 3_000;
export const SEVEN_SECONDS_MS = 7_000;
export const TWENTY_SECONDS_MS = 20_000;
export const ONE_MINUTE_MS = 60_000;
export const FIVE_MINUTE_MS = 5 * ONE_MINUTE_MS;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const FOUR_HOUR_MS = 4 * ONE_HOUR_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export const THIRTY_DAYS_MS = 30 * ONE_DAY_MS;
