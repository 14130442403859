import { useQuery } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";
import getConfig from "next/config";
import { z } from "zod";
import { ONE_HOUR_MS, queryKey } from "../queryKey";
import { extractZodErrorPaths } from "../useHttpClientV2";

const userInfoTokenResponseSchema = z.object({
  Data: z.object({
    UserInfoToken: z.string(),
    EulaURLs: z
      .array(
        z.object({
          Uri: z.string(),
          TTL: z.number(),
        }),
      )
      .nullable(),
  }),
});
type UserInfoTokenResponse = z.infer<typeof userInfoTokenResponseSchema>;

export const useUserInfoToken = (
  keycloakToken: string | undefined,
  enable?: boolean,
) => {
  let tokenExp = "unset";
  if (keycloakToken) {
    tokenExp = jwtDecode<{ exp: number }>(keycloakToken).exp.toString();
  }
  return useQuery(
    [queryKey.userInfoToken, tokenExp],
    async () => {
      const { publicRuntimeConfig } = getConfig();
      const API_URL = publicRuntimeConfig.ACTIVE_API_V_TWO_URL;
      const url = `${API_URL}/api/v1.1/UserAdmin/GetUserInfoToken`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${keycloakToken}`,
        },
      });

      if (response.ok) {
        const json = await response.json();

        const result = userInfoTokenResponseSchema.safeParse(json);

        if (!result.success) {
          console.error(extractZodErrorPaths(result.error));
        }

        return (json as UserInfoTokenResponse).Data;
      }
      throw new Error("No Token Returned");
    },
    {
      enabled: !!keycloakToken && enable,
      retry: 3,
      staleTime: ONE_HOUR_MS,
    },
  );
};
