import { FunctionComponent, PropsWithChildren } from "react";
import { ClientOnly } from "../utils/ClientOnly";
import { useMediaQuery } from "../utils/useMediaQuery";

export const IfViewportWidth: FunctionComponent<
  PropsWithChildren<{ type: "min" | "max"; width?: number }>
> = ({ type, width = 480, children }) => {
  const condition = useMediaQuery(`(${type}-width: ${width}px)`);

  return <ClientOnly>{condition && children}</ClientOnly>;
};
