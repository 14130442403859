import { MessageEvent } from "./webViewMessages";

type WindowWithPostMessage = {
  ReactNativeWebView: {
    postMessage: (message: string) => void;
  };
};

export const isInApp = (window: unknown): window is WindowWithPostMessage => {
  return (
    window !== null &&
    typeof window === "object" &&
    "ReactNativeWebView" in window &&
    typeof window.ReactNativeWebView === "object" &&
    "postMessage" in window
  );
};
export type SupportedInAppCommunicationMessageTypes =
  | "PLAY_SOUND-v1"
  | "PRINT_HTML_CONTENT-v1"
  | "UPDATE_WRAPPER_COLORS-v1"
  | "FRONTLOAD_TIMER_AUDIO-v1"
  | "REQUEST_NOTIFICATION_PERMISSIONS-v1"
  | "SETUP_NOTIFICATIONS-v1";

export const getSupportedInAppCommunicationMessageTypes = (
  window: unknown,
): SupportedInAppCommunicationMessageTypes[] => {
  try {
    if (window !== null && typeof window === "object") {
      if (
        "nativeWrapperAppFeatures" in window &&
        typeof window.nativeWrapperAppFeatures === "string"
      ) {
        return JSON.parse(window.nativeWrapperAppFeatures)
          .supportedInAppCommunicationMessageTypes as SupportedInAppCommunicationMessageTypes[];
      }
      if (
        "ReactNativeWebView" in window &&
        typeof window.ReactNativeWebView === "object" &&
        window.ReactNativeWebView !== null &&
        "injectedObjectJson" in window.ReactNativeWebView &&
        typeof window.ReactNativeWebView.injectedObjectJson === "function"
      ) {
        const injectedObjectJSON = JSON.parse(
          window.ReactNativeWebView.injectedObjectJson(),
        );
        if (injectedObjectJSON) {
          return injectedObjectJSON.nativeWrapperAppFeatures
            .supportedInAppCommunicationMessageTypes as SupportedInAppCommunicationMessageTypes[];
        }
      }
    }
  } catch (e) {
    console.log("Error with findAppFeatures", e);
    //catch errors in case of malformed window
  }
  return [];
};

export const useAppCommunication = () => {
  const sendMessageToApp = (message: MessageEvent) => {
    if (isInApp(window)) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  };

  return { sendMessageToApp };
};
