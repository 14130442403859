import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";

export type LOCALES = "en-us" | "es-es" | "ja-jp" | "pseudo";

type Language = {
  locale: LOCALES;
  msg: MessageDescriptor;
  territory?: string;
  rtl: boolean;
  display: string;
};

export const languages: Language[] = [
  {
    locale: "en-us",
    msg: msg`English`,
    territory: "US",
    rtl: false,
    display: "English",
  },
  {
    locale: "es-es",
    msg: msg`Spanish - español`,
    territory: "ES",
    rtl: false,
    display: "Spanish - español",
  },
  {
    locale: "ja-jp",
    msg: msg`Japanese - 日本語`,
    territory: "JP",
    rtl: false,
    display: "Japanese - 日本語",
  },
];

if (process.env.NODE_ENV !== "production") {
  languages.push({
    locale: "pseudo",
    msg: msg`Pseudo`,
    rtl: false,
    display: "Pseudo",
  });
}

export default languages;
