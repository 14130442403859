import AccessibilityNewRounded from "@mui/icons-material/AccessibilityNewRounded";
import Image from "next/image";
import { FunctionComponent } from "react";
import { useUserDetails } from "../../api-hooks/auth/useUserDetails";
import { useAccount } from "../../api-hooks/useAccounts";
import style from "./EgoscueLogo.module.css";

export const EgoscueLogo: FunctionComponent<{
  flipped?: boolean;
  large?: boolean;
}> = ({ flipped = false, large = false }) => {
  const userDetails = useUserDetails();

  const account = useAccount({
    accountId: userDetails.data?.accountId ?? "-1",
    type: "EPeteAccountId",
  });

  return userDetails.status === "success" &&
    account.status === "success" &&
    userDetails.data.userType === "Affiliate" ? (
    <p className={style.logoText}>
      <AccessibilityNewRounded style={{ color: "var(--primaryBright)" }} />
      {account.data.Name}
    </p>
  ) : (
    <Image
      height={large ? 100 : 50}
      width={large ? 263.2 : 131.6}
      src={
        !flipped ? "/images/logo-black-text.svg" : "/images/logo-white-text.svg"
      }
      alt="The Egoscue Method"
      priority={true}
    />
  );
};
