import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useRouter } from "next/router";
import { useNextJsLayoutEffect } from "../utils/useNextJsLayoutEffect";

export const usePageTransitionAnimationContainer = () => {
  const [animated, enableAnimations] = useAutoAnimate();
  const router = useRouter();

  useNextJsLayoutEffect(() => {
    if (
      router.pathname.startsWith("/app") ||
      router.pathname.startsWith("/clients/[clientId]/visits/[visitId]")
    ) {
      enableAnimations(false);
    } else {
      enableAnimations(true);
    }
  }, [router.pathname]);

  return animated;
};
