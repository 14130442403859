import { RefObject, useEffect, useRef } from "react";

type LottieAnimation = "celebration" | "loading";

export const useLottieAnimation = (
  container: RefObject<Element>,
  animation: LottieAnimation,
) => {
  const isInitialMount = useRef<boolean>(true);

  useEffect(() => {
    if (container.current && isInitialMount.current) {
      isInitialMount.current = false;
      import("lottie-web").then((Lottie) => {
        if (container.current) {
          Lottie.default.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: `/lottie/${animation}.json`,
          });
        }
      });
    }
  }, []);
};
