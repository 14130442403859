import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { useUserDetails } from "../auth/useUserDetails";
import { useHttpClientV2 } from "../useHttpClientV2";
import { useUser } from "../useUsers";
import { crmQueryKey } from "./crmQueryKey";
import { crmQueryRefreshConfig } from "./crmQueryRefreshConfig";
import { useIsInitialCrmQueryPossible } from "./useIsCrmQueryPossible";

export const useCrmClientSync = (userId?: string) => {
  const { request } = useHttpClientV2();
  const userDetails = useUserDetails();

  const resolvedId = userId ?? userDetails.data?.userId ?? "-1";
  const isInitialCrmQueryPossible = useIsInitialCrmQueryPossible(resolvedId);

  const user = useUser(parseInt(resolvedId));

  return useQuery(
    [crmQueryKey.crmClientSync, resolvedId, user.data?.ConfirmedFieldsDate],
    async () => {
      const result = await request({
        method: "POST",
        uri: "api/v1/CrmApi/SyncMbo",
        body: {
          Id: user.data?.UserId,
          Email: user.data?.Email,
          Phone: user.data?.Phone,
          Birthday: user.data?.Birthday,
          ClientConfirmed: userDetails.data?.userType === "Client",
        },
        responseSchema: z.object({
          Data: z.array(z.number()),
        }),
      });
      return result.Data;
    },
    {
      enabled:
        isInitialCrmQueryPossible.status === "success" &&
        isInitialCrmQueryPossible.data &&
        user.status === "success",
      ...crmQueryRefreshConfig,
    },
  );
};
