import Notifications from "@mui/icons-material/Notifications";
import Link from "next/link";
import { useSupplementalRolePermissionDetails } from "../../api-hooks/useSupplementalRolePermissionDetails";
import {
  useOutstandingTherapistTasksCount,
  useOutstandingTherapistTasksForAccountCount,
} from "../../api-hooks/useTherapistTasks";
import { checkPermission } from "../../shared/FeatureFlag/PermissionRequired";
import { button } from "../../styles";
import style from "./TaskNotifications.module.css";

export const TaskNotifications = () => {
  const outstandingTherapistTasksCount = useOutstandingTherapistTasksCount();

  const userRoles = useSupplementalRolePermissionDetails(undefined);
  const accountTasks = useOutstandingTherapistTasksForAccountCount(
    userRoles.status === "success" &&
      checkPermission(
        "UPLDMNGR",
        userRoles.data.UserSupplementalRoles,
        userRoles.data.SupplementalRoleDetails,
      ),
  );

  return (
    <Link href="/tasks" className={button.actionButton} title="Tasks">
      <div className={style.container}>
        <Notifications />
        {outstandingTherapistTasksCount.status === "success" &&
          outstandingTherapistTasksCount.data > 0 && (
            <span className={style.indicator}>
              {outstandingTherapistTasksCount.data}
            </span>
          )}
        {accountTasks.status === "success" && accountTasks.data > 0 && (
          <span
            className={style.indicator}
            style={{ right: "14px", backgroundColor: "var(--primary)" }}
          >
            {accountTasks.data}
          </span>
        )}
      </div>
    </Link>
  );
};
