import { Trans, t } from "@lingui/macro";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import dynamic from "next/dynamic";
import Image from "next/image";
import { FunctionComponent, useState } from "react";
import { logoutCallback } from "../../api-hooks/auth/logoutCallback";
import {
  Eula,
  useEulas,
  useSignEulaMutation,
} from "../../api-hooks/useDocuments";
import { InlineNotification } from "../../shared/InlineNotification/InlineNotification";
import { useResetAllPersistedUserPreferences } from "../../shared/persistedUserPreferences";
import { button } from "../../styles";
import { PageError } from "../ClientApp/shared/PageError";
import { AppLoadingSpinner } from "../ClientApp/shared/PageLoading";
import style from "./EulaSigner.module.css";

export const EulaSigner: FunctionComponent<{
  documents: string;
  URLs: Eula[] | undefined;
}> = ({ documents, URLs }) => {
  //viewer will load as null until import resolves or this state is set to false
  const [canUsePDFViewer, setCanUsePDFViewer] = useState(true);

  const resetAllPersistedUserPreferences =
    useResetAllPersistedUserPreferences();

  const PDFViewer = dynamic(
    () =>
      import("../../shared/PDF/PDFViewer").then((namedExp) => {
        return namedExp.PDFViewer;
      }),
    {
      ssr: false,
      loading: ({ error }) => {
        if (error) {
          setCanUsePDFViewer(false);
        }
        return null;
      },
    },
  );

  const parsedDocs: string[] = JSON.parse(documents);

  const matchedURIsToDocs: string[] = [];
  if (URLs && URLs.length > 0 && URLs.length === parsedDocs.length) {
    parsedDocs.map((doc) => {
      const matchedUri = URLs.find((urlObj) => {
        return urlObj.Uri.includes(doc);
      })?.Uri;
      if (matchedUri) {
        matchedURIsToDocs.push(matchedUri);
      }
    });
  }

  const docsToSign = useEulas(
    parsedDocs as string[],
    matchedURIsToDocs,
    URLs?.[0].TTL,
  );

  const [activeDoc, setActiveDoc] = useState(0);

  const signEulas = useSignEulaMutation(parsedDocs);

  const matchingEulaUrl = docsToSign.data?.[activeDoc];

  if (docsToSign.status === "success") {
    return matchingEulaUrl ? (
      <div className={style.main}>
        <div className={style.innerMain}>
          <Image
            src="/images/logo-black-text.svg"
            alt="The Egoscue Method"
            height={50}
            width={131.6}
          />
          <p className={style.paragraph}>
            <Trans>
              You must accept the terms of the agreement(s) to continue.
            </Trans>
          </p>
          {canUsePDFViewer && (
            <div className={style.pdfcontainer}>
              <PDFViewer
                fileUrl={`${matchingEulaUrl}#view=FitH&toolbar=0`}
                pageBreakAfterPage
                pageBreakBeforeDocument
                widthPercent={95}
                renderOnError={
                  <InlineNotification
                    type="info"
                    message={t`Your session has timed out. Please wait while the document reloads.`}
                  />
                }
              />
            </div>
          )}
          {!canUsePDFViewer && (
            <iframe
              src={`${matchingEulaUrl}#view=FitH&toolbar=0`}
              className={style.eula}
            />
          )}
          <div className={`${button.buttonRow} ${style.topPadding}`}>
            <button
              className={button.buttonSecondary}
              onClick={(e) => {
                e.preventDefault();
                resetAllPersistedUserPreferences();
                logoutCallback();
              }}
            >
              <Close /> <Trans>Reject</Trans>
            </button>
            <button
              className={button.buttonPrimary}
              onClick={async (e) => {
                e.preventDefault();
                if (activeDoc !== parsedDocs.length - 1) {
                  setActiveDoc(activeDoc + 1);
                } else {
                  await signEulas.mutateAsync();
                }
              }}
            >
              <Check /> <Trans>Accept</Trans>
            </button>
          </div>
        </div>
      </div>
    ) : (
      <InlineNotification
        type="error"
        message={t`There seems to be an issue loading the page.`}
      />
    );
  }
  if (docsToSign.status === "error") {
    <PageError
      errorMessage={t`There seems to be an issue loading the agreement, please login and try again.`}
    />;
  }
  return (
    <div className={style.loadingContainer}>
      <AppLoadingSpinner />
    </div>
  );
};
