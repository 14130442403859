import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { enUS } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { ja } from "date-fns/locale/ja";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const loadCatalog = async (locale: string) => {
  const { messages } = await import(`./locales/${locale}.po`);

  return messages;
};

export const getDateFnsLocale = () => {
  const currentLang = i18n.locale;
  switch (currentLang) {
    case "es-es":
      return es;
    case "ja-jp":
      return ja;
    case "en-us":
    default:
      return enUS;
  }
};

export const useI18n = () => {
  const router = useRouter();
  const locale = router.locale || router.defaultLocale!;

  useEffect(() => {
    loadCatalog(locale).then((messages) => {
      i18n.loadAndActivate({ locale, messages });
    });
  }, [locale]);

  return i18n;
};

export const i18nHardCoded = (input: string): string => {
  const internalizedHardcodedReadableStrings: Record<string, string> = {
    Therapist: t`Therapist`,
    Affiliate: t`Affiliate`,
    Client: t`Client`,
    SuperUser: t`SuperUser`,
    Unknown: t`Unknown`,
    ClinicOwner: t`ClinicOwner`,
  };
  if (input in internalizedHardcodedReadableStrings) {
    return internalizedHardcodedReadableStrings[input];
  }
  return input;
};
