import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom, useSetAtom } from "jotai";
import jwtDecode from "jwt-decode";
import Keycloak from "keycloak-js";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { clientAccountViewAtom } from "../../shared/persistedUserPreferences";
import { useInterval } from "../../utils/useInterval";
import { isInApp } from "../app/useAppCommunication";
import { THREE_SECONDS_MS, queryKey } from "../queryKey";
import { loginAtom, signingAtom } from "./login";
import { DecodedUserInfoToken, UserType } from "./useUserDetails";
import { useUserInfoToken } from "./useUserInfoToken";

export const getLoggedInPath = (userType: UserType) => {
  const query = new URLSearchParams(window.location.search);

  const returnPath = `${query.get("returnPath") ?? ""}`;

  if (returnPath !== "" && returnPath !== "/") {
    return returnPath;
  }

  if (isInApp(window)) {
    return "/app/home";
  }

  if (userType === "SuperUser") {
    return "/admin/accounts";
  }
  if (userType === "Client") {
    return "/app/home";
  }

  return "/clients";
};

export const noAuthPaths = [
  "/",
  "/logged-out",
  "/appointment",
  "/visitor",
  "/contracts_and_memberships",
  "/mboscratchpad",
];

export const useAuthentication = () => {
  const { publicRuntimeConfig } = getConfig();

  const router = useRouter();

  const queryClient = useQueryClient();

  const loggingOut = useRef(false);
  const [loginState, setLoginState] = useAtom(loginAtom);
  const setOutstandingEulas = useSetAtom(signingAtom);
  const isRefreshing = useRef(false);
  const attemptedRefreshes = useRef(0);

  const [keycloak, setKeycloak] = useState<Keycloak | undefined>();

  const getKeycloak = () => {
    return new Keycloak({
      url: publicRuntimeConfig.AUTH_URL,
      realm: "epete3",
      clientId: "epete3-app",
    });
  };

  const revertLogin = (keycloak: Keycloak) => {
    if (router.pathname === "/" || !noAuthPaths.includes(router.pathname)) {
      loggingOut.current = true;
      if (loginState.isLoggedIn !== false) {
        setLoginState({ isLoggedIn: false });
      }
      keycloak.login();
    }
  };

  //set up serial queries for keycloak and out api (see: https://tanstack.com/query/latest/docs/framework/react/guides/dependent-queries)
  const { status: keycloakStatus, data: authenticated } = useQuery(
    [queryKey.keyCloakInitialization],
    async () => {
      if (keycloak) {
        const init = await keycloak.init(
          loginState.isLoggedIn
            ? {
                token: loginState.accessToken,
                idToken: loginState.idToken,
                refreshToken: loginState.refreshToken,
              }
            : {},
        );
        return init;
      }
    },
    {
      //the adapter will throw errors if initialized more than once, so eliminate retry
      retry: false,
      enabled: !!keycloak && !keycloak.authenticated,
    },
  );

  const userInfoToken = useUserInfoToken(
    keycloak?.token,
    keycloakStatus === "success" && !!authenticated,
  );
  const setClientAccountView = useSetAtom(clientAccountViewAtom);

  if (
    keycloak &&
    ((keycloakStatus === "success" && !authenticated) ||
      keycloakStatus === "error" ||
      userInfoToken.status === "error")
  ) {
    console.error("useAuthentication error");
    revertLogin(keycloak);
  }

  const setUserLogin = () => {
    if (
      userInfoToken.status === "success" &&
      keycloak?.token &&
      keycloak?.tokenParsed &&
      keycloak?.tokenParsed.exp &&
      keycloak?.refreshToken &&
      keycloak?.idToken &&
      userInfoToken.data.UserInfoToken
    ) {
      setLoginState({
        isLoggedIn: true,
        accessToken: keycloak.token,
        accessExpiryTime: keycloak.tokenParsed.exp,
        userInfoToken: userInfoToken.data.UserInfoToken,
        idToken: keycloak.idToken,
        refreshToken: keycloak.refreshToken,
        loginTime: new Date().getTime() / 1000,
      });

      if (userInfoToken.data.EulaURLs) {
        setOutstandingEulas(userInfoToken.data.EulaURLs);
      }
      if (router.pathname === "/") {
        const decodedUserInfo: DecodedUserInfoToken = jwtDecode(
          userInfoToken.data.UserInfoToken,
        );
        if (decodedUserInfo.account_type === "Affiliate") {
          try {
            setClientAccountView(parseInt(decodedUserInfo.account_id));
          } catch (error) {
            console.log(error);
          }
        }
        router.push(getLoggedInPath(decodedUserInfo.user_type), undefined, {
          shallow: true,
        });
      }
    }
  };

  const refreshTokenIfNecessary = () => {
    if (loginState.isLoggedIn) {
      //give a few extra seconds for throttling of interval
      if (
        !isRefreshing.current &&
        loginState.accessExpiryTime - new Date().getTime() / 1000 < 65
      ) {
        isRefreshing.current = true;

        if (keycloak && authenticated) {
          keycloak
            .updateToken(90)
            .then((isRefreshed) => {
              if (isRefreshed && keycloak.token) {
                setKeycloak(keycloak);
                queryClient.invalidateQueries({
                  queryKey: [queryKey.userInfoToken],
                });
              }
            })
            .catch((e: Error) => {
              console.error("Failed updating token: ", e);
              if (attemptedRefreshes.current > 3) {
                loggingOut.current = true;
                setLoginState({ isLoggedIn: false });
                keycloak.login();
              }
              attemptedRefreshes.current += 1;
            });
        }
        isRefreshing.current = false;
      }
    }
  };

  useMemo(() => {
    if (!loggingOut.current) {
      setUserLogin();
    }
  }, [userInfoToken.dataUpdatedAt, keycloak?.token]);

  useInterval(() => {
    refreshTokenIfNecessary();
  }, THREE_SECONDS_MS);

  //keycloak relies on the document existing
  useEffect(() => {
    if (!keycloak) {
      queryClient.invalidateQueries({
        queryKey: [queryKey.keyCloakInitialization],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKey.userInfoToken],
      });
      setKeycloak(getKeycloak());
    }
  }, [keycloak]);
};
