import { useQuery } from "@tanstack/react-query";
import z from "zod";
import { useUserDetails } from "./auth/useUserDetails";
import { ONE_HOUR_MS, queryKey } from "./queryKey";
import { useHttpClientV2 } from "./useHttpClientV2";

export const supplementalRolePermissionSchema = z.object({
  SupplementalRoleId: z.number(),
  SupplementalRoleName: z.string(),
  SupplementalRoleDescription: z.string(),
  SupplementalRolePermissionId: z.number(),
  PermissionType: z.string(),
  PermissionCode: z.string(),
  SupplementalRolePermissionName: z.string(),
  SupplementalRolePermissionDescription: z.string(),
  Audience: z.string(),
});
export type supplementalRolePermission = z.infer<
  typeof supplementalRolePermissionSchema
>;

export const userSupplementalRoleSchema = z.object({
  Created: z.string(),
  LastModified: z.string(),
  CreatedBy: z.string().nullable(),
  LastModifiedBy: z.string().nullable(),
  Id: z.number(),
  UserId: z.number(),
  SupplementalRoleId: z.number(),
});
export type userSupplementalRole = z.infer<typeof userSupplementalRoleSchema>;

export const useSupplementalRolePermissionDetails = (
  userId: number | undefined,
) => {
  const { request, isValidLoggedIn } = useHttpClientV2();
  const userDetails = useUserDetails();

  return useQuery(
    [
      queryKey.supplementalRolePermissionDetails,
      userId ? userId : "supplementalRolePermissionDetails",
    ],
    async () => {
      const result = await request({
        method: "GET",
        uri:
          "api/v1.1/UserAdmin/GetSupplementalRoleDetails" +
          (userDetails.data?.userType === "SuperUser"
            ? userId && userId !== -1
              ? `?UserId=${userId}`
              : ""
            : `?UserId=${userDetails.data?.userId}`),
        responseSchema: z.object({
          Data: z.object({
            SupplementalRoleDetails: z.array(supplementalRolePermissionSchema),
            UserSupplementalRoles: z.array(userSupplementalRoleSchema),
          }),
        }),
      });
      return result.Data;
    },
    {
      enabled: isValidLoggedIn && userDetails.status === "success",
      refetchOnWindowFocus: false,
      staleTime: ONE_HOUR_MS,
    },
  );
};
