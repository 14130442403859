import { useAtomValue } from "jotai";
import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { useAppCommunication } from "../api-hooks/app/useAppCommunication";
import { useUserDetails } from "../api-hooks/auth/useUserDetails";
import { clientAccountViewAtom } from "../shared/persistedUserPreferences";
import { ClientOnly } from "../utils/ClientOnly";
import style from "./ThemeProvider.module.css";

export const ThemeProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { sendMessageToApp } = useAppCommunication();

  const clientAppAccountView = useAtomValue(clientAccountViewAtom);

  useEffect(() => {
    sendMessageToApp({
      type: "UPDATE_WRAPPER_COLORS-v1",
      colors: {
        top: "#eee",
        bottom: "#fff",
      },
    });
  }, []);

  const userDetails = useUserDetails();

  let theme = style.lightTheme;

  if (
    clientAppAccountView !== -1 &&
    userDetails.status === "success" &&
    userDetails.data.accountType === "Affiliate"
  ) {
    theme = style.genericAffiliateTheme;
  }

  return (
    <ClientOnly>
      <div className={`${style.themeProvider} ${theme}`}>{children}</div>
    </ClientOnly>
  );
};
