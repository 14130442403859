import { FunctionComponent, PropsWithChildren } from "react";
import style from "./PrintProvider.module.css";

export const usePrintContainer = () =>
  document.getElementById("PrintContentContainer") ?? document.body;

export const PrintProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <div className={style.regularContent}>{children}</div>
      <div id={"PrintContentContainer"} className={style.printContent}></div>
    </>
  );
};
