import { captureException } from "@sentry/nextjs";
import { useUserDetails } from "../../api-hooks/auth/useUserDetails";

export const useEventTracking = () => {
  const userDetails = useUserDetails();

  return {
    trackCriticalError: (
      action: string,
      category:
        | "CRITICAL_API"
        | "CRITICAL_UNCAUGHT"
        | "SCHEMA_MISMATCH"
        | "AUTHENTICATION"
        | "NEW_VISIT_NO_MBO"
        | "NEW_VISIT_NO_MBO_FORCED",
      type: string,
      logEventId?: string,
    ) => {
      captureException(
        new Error(
          JSON.stringify({
            action,
            type,
            category,
          }),
        ),
        {
          user: { id: userDetails.data?.userId },
          ...(logEventId ? { tags: { LogEventId: logEventId } } : {}),
        },
      );
    },
  };
};
