import Check from "@mui/icons-material/Check";
import { FunctionComponent, ReactNode } from "react";
import style from "./Checkbox.module.css";

export const Checkbox: FunctionComponent<{
  isChecked: boolean;
  toggle: () => void;
  label: ReactNode | string;
}> = ({ isChecked, toggle, label }) => {
  return (
    <label className={style.container}>
      <input
        checked={isChecked}
        onChange={() => {
          toggle();
        }}
        type="checkbox"
        className={style.input}
      />
      <span
        className={`${style.checkbox} ${
          isChecked ? style.checkboxChecked : style.checkboxUnchecked
        }`}
      >
        {isChecked && <Check />}
      </span>
      {label}
    </label>
  );
};
