import { useCallback, useEffect, useRef } from "react";

export const useVisibility = (
  visibleCallback: () => void,
  hiddenCallback: () => void,
  key: string,
) => {
  const initialVis = useRef("visible");
  const onVisChange = () => {
    if (document.hidden) {
      visibility(false);
    } else {
      visibility(true);
    }
  };
  const visibility = useCallback(
    (vis: boolean) => {
      if (vis) {
        visibleCallback();
      } else {
        hiddenCallback();
      }
    },
    [visibleCallback, hiddenCallback, key],
  );
  useEffect(() => {
    initialVis.current = document.hidden ? "hidden" : "visible";
    document.addEventListener("visibilitychange", onVisChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisChange);
    };
  }, []);
  return initialVis;
};
