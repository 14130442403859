import { useAtom } from "jotai";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { z } from "zod";
import { useEventTracking } from "../shared/analytics/useEventTracking";
import { onlyUnique } from "../utils/onlyUnique";
import { isRefreshTokenExpiredOrMalformed, loginAtom } from "./auth/login";
import { parseUserInfoToken } from "./auth/useUserDetails";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type Request = <T>(body: {
  method: HttpMethod;
  uri: string;
  noAuth?: boolean;
  headers?: HeadersInit;
  body?: string | Record<string, unknown> | FormData;
  noContentType?: boolean;
  responseSchema: z.ZodType<T> | null;
}) => Promise<T>;

export const extractZodErrorPaths = (zodError: z.ZodError): string => {
  return zodError.issues
    .map((issue) => `${issue.path?.[0] ?? ""} ${issue.path?.[2] ?? ""}`)
    .filter(onlyUnique)
    .join(" + ");
};

export const useHttpClientV2 = () => {
  const { publicRuntimeConfig } = getConfig();
  const API_URL = publicRuntimeConfig.ACTIVE_API_V_TWO_URL;

  const { trackCriticalError } = useEventTracking();

  const [login, setLogin] = useAtom(loginAtom);
  const router = useRouter();

  const request: Request = async ({
    method,
    uri,
    headers,
    body,
    noContentType,
    responseSchema,
    noAuth = false,
  }) => {
    const url = `${API_URL}/${uri}`;
    headers = {
      ...headers,
      ...(login.isLoggedIn && !noAuth
        ? {
            Authorization: `Bearer ${login.accessToken}`,
            UserInfo: login.userInfoToken,
          }
        : {}),
      ...(noContentType
        ? {}
        : { "Content-Type": "application/json;charset=UTF-8" }),
    };
    const parsedBody = (
      noContentType
        ? body
        : typeof body !== "string"
          ? JSON.stringify(body)
          : body
    ) as string | FormData;

    const response = await fetch(url.toString(), {
      headers,
      method,
      body: parsedBody,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let json: any = null;

    try {
      json = await response.json();
    } catch (error) {
      console.log(error);
    }

    if (response.status === 500) {
      trackCriticalError(
        `ePete-api/${uri}`,
        "CRITICAL_API",
        `500 - ${response.statusText}`,
        json?.LogEventId ? json.LogEventId : "",
      );
    }

    if (json) {
      if (response.ok) {
        responseSchema?.safeParseAsync(json).then((result) => {
          if (result.success === false) {
            console.error(result.error);
            const zodError = extractZodErrorPaths(result.error);
            console.error(`Schema mismatch! ePete-api/${uri} ${zodError}`);
            trackCriticalError(
              `${method} ePete-api/${uri}`,
              "SCHEMA_MISMATCH",
              zodError,
            );
          }
        });
        return json;
      } else if (
        json.IsError &&
        json.Error?.Type === "ApiValidationException" &&
        Array.isArray(json.Error?.ValidationErrors)
      ) {
        let errorMessage = (
          json.Error.ValidationErrors.join(". ") as string
        ).trim();

        if (errorMessage[errorMessage.length - 1] !== ".") {
          errorMessage += ".";
        }
        throw new Error(errorMessage);
      }
    }

    if (
      response.status === 401 &&
      login.isLoggedIn &&
      isRefreshTokenExpiredOrMalformed(login)
    ) {
      router.push("/").then(() => {
        setLogin({ isLoggedIn: false });
        window.location.reload();
      });
    }

    throw new Error(response.statusText);
  };

  const getIsActiveUser = () => {
    if (login.isLoggedIn) {
      const details = parseUserInfoToken(login.userInfoToken);

      return (
        (details.data?.outstandingEulas ?? []).length === 0 &&
        details.data?.userStatus === "Active" &&
        (details.data?.accountStatus === "Active" ||
          details.data?.userType === "Client")
      );
    }

    return false;
  };

  const isValidLoggedIn =
    login.isLoggedIn &&
    getIsActiveUser() &&
    !isNaN(login.accessExpiryTime) &&
    !(login.accessExpiryTime - new Date().getTime() / 1000 < 60);

  return { request, isValidLoggedIn };
};
