export const crmQueryKey = {
  crmClientSync: "crmClientSync",
  crmClientMemberships: "crmClientMemberships",
  crmClientPurchases: "crmClientPurchases",
  crmClientCardDetails: "crmClientCardDetails",
  crmClientAccountBalance: "crmClientAccountBalance",
  crmBookables: "crmBookables",
  crmBookableSessionTypes: "crmBookableSessionTypes",
  crmSites: "crmSites",
  crmClientVisits: "crmClientVisits",
  crmSaleMemberships: "crmSaleMemberships",
  crmClientLocations: "crmClientLocations",
  crmSiteLocations: "crmSiteLocations",
};
