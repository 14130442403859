import { QueryStatus } from "@tanstack/react-query";
import { FunctionComponent, PropsWithChildren, useRef } from "react";
import { InlineNotification } from "../../../shared/InlineNotification/InlineNotification";
import { useAnimationContainer } from "../../../utils/useAnimationContainer";
import { useLottieAnimation } from "../../../utils/useLottieAnimation";
import style from "./PageLoading.module.css";

export const AppLoadingSpinner = () => {
  const container = useRef<HTMLDivElement>(null);
  useLottieAnimation(container, "loading");

  return <div className={style.spinner} ref={container}></div>;
};

export const PageLoading: FunctionComponent<
  {
    status: QueryStatus;
    errorMessage: string;
    noHeightFill?: boolean;
  } & PropsWithChildren
> = ({ children, status, errorMessage, noHeightFill = false }) => {
  const animated = useAnimationContainer();

  return (
    <div
      ref={animated}
      className={style.container}
      style={
        noHeightFill
          ? { height: "unset", display: "flex", flexDirection: "column" }
          : {}
      }
    >
      {status === "success" ? (
        children
      ) : (
        <div className={style.loadingContainer}>
          {status === "loading" && <AppLoadingSpinner />}
          {status === "error" && (
            <InlineNotification
              textDisplayOption="unbound"
              type="error"
              message={errorMessage}
            />
          )}
        </div>
      )}
    </div>
  );
};
